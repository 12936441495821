<template>
  <div id="event_info" class="event_public">
    <AnnualHeader :eventHeaderItems="eventHeaderItems" />

    <PageSubHeader :pageSubHeader="pageSubHeader" :urlQuery="urlQuery" class="annual" />

    <div class="information">
      <div class="title w1100 mg-b-50">
        <h3 class="txt-bold">{{ pageTitle }}</h3>
        <img src="@/statics/img/index/icon_title2.svg" alt="">
      </div>
      <div class="w1000">
        <section id="form_detail">
          <div class="payment_member">
            <h4 class="payment_member_name txt-bold mg-tb-10">{{ user.realname }}</h4>
            <p class="payment_member_profession">(身分：<span id="identity">{{ roleName }}</span>)</p>
          </div>

          <!-- table area -->
          <div class="table_box">
            <table class="txt-center">
              <thead>
                <tr>
                  <th class="td-main txt-left"><p>{{ tableHead1 }}</p></th>
                  <th class="min100"><p>{{ tableHead2 }}</p></th>
                  <th class="min130"><p>{{ tableHead3 }}</p></th>
                </tr>
              </thead>
              <tbody id="table_data">

                <tr>
                  <td :data-title="tableHead1" class="td-main txt-left">
                    <p>其他 <template v-if="eventInfo.name">({{ eventInfo.name }})</template></p>
                  </td>
                  <td :data-title="tableHead2"><p>{{ eventPrice }}</p></td>
                  <td :data-title="tableHead3"></td>
                </tr>

              </tbody>
            </table>
          </div>

          <div class="form_box">
            <form>
              <div class="form_interactive">
                <div class="fixed_info w50">
                  <p class="fixed_info_title">付款方式</p>
                  <p class="fixed_info_content">信用卡</p>
                </div>
                <div class="fixed_info w50">
                  <p class="fixed_info_title">總金額</p>
                  <p class="fixed_info_content">{{ eventPrice }}</p>
                </div>
              </div>
            </form>

            <!-- 綠界金流按鈕 -->
            <div v-html="resHtml"></div>
            <div class="errMsg txt-center txt-red">
              <p class="h3">{{ paymentErrorMsg }}</p>
            </div>
            <div class="mycourse_btn txt-center" v-if="showBtn">
              <router-link
                to="/register/record"
                class="btn"
              >活動紀錄</router-link>
            </div>
          </div>

        </section>
      </div>
    </div>
  </div>
</template>

<script>
/** Components */
import AnnualHeader from '@/components/AnnualHeader.vue';
import PageSubHeader from '@/components/PageSubHeader.vue';

/** Functions */
import { RegisterEventSubHeader } from '@/lib/const';
import { annualView, userInfo, eventsMemberApply } from '@/lib/http';
import { mapGetters } from 'vuex';

export default {
  name: 'RegisterEventConfirm',
  data() {
    return {
      /**
       * Static Data
       */
      ...RegisterEventSubHeader,
      tableHead1: '費用名稱',
      tableHead2: '金額',
      tableHead3: '備註',
      componentKey: 0,
      eventID: '',
      showBtn: false,
      /**
       * Axios Response Data
       */
      eventInfo: {},
      user: {
        realname: '',
        level: '',
      },
      resHtml: '',
      paymentErrorMsg: '',
    };
  },
  created() {
    if (this.$route.query && this.$route.query.event_id) {
      this.eventID = this.$route.query.event_id;
      annualView({ uuid: this.eventID }).then((result) => {
        const target = result.data.result.data;

        this.eventInfo = target;

        /** Refresh EventHeader */
        this.componentKey += 1;
      });

      if (this.hasToken) {
        userInfo().then((result) => {
          // console.log(result);
          this.user.realname = result.realname;
          this.user.level = result.level;
        });
      }

      // console.log('cookie', this.$cookies.get('annualApplyInfo').pay_id);

      eventsMemberApply({ ...this.$cookies.get('annualApplyInfo'), return_url: '/register/annual/success' }).then((result) => {
        if (result.data.result.html) {
          this.resHtml = result.data.result.html;
        } else if (result.data.status === true) {
          this.paymentErrorMsg = '免費課程已報名成功，請至活動紀錄查詢';
          this.showBtn = true;
        } else {
          this.paymentErrorMsg = result.data.message;
          this.showBtn = true;
        }
      });
    } else {
      this.assignUrlRedirect('/register/event');
      this.updateModalInfoContent('尚未選取任何學術活動，請返回總覽頁選擇');
      this.toggleModalInfo(true);
    }
  },
  computed: {
    ...mapGetters([
      'hasToken',
      'isAuthenticated',
    ]),
    urlQuery() {
      return {
        name: 'event_id',
        value: this.eventID,
      };
    },
    pageTitle() {
      if (this.isAuthenticated) {
        return '會員報名';
      }
      return '非會員報名';
    },
    roleName() {
      if (this.user && this.user.level) {
        if (parseInt(this.user.level, 10) === 4) {
          return '護理人員';
        }
      }
      return '醫師';
    },
    eventPrice() {
      let price = '';
      if (this.user && this.user.level && this.eventInfo && this.eventInfo.price) {
        price = this.eventInfo.price[parseInt(this.user.level, 10)];
      }
      return price;
    },
    paymentType() {
      // console.log('cookie', JSON.parse(this.$cookies.get('annualApplyInfo')));
      if (this.$cookies.get('annualApplyInfo')) {
        return '信用卡';
      }
      return '';
    },
    hasFractionA() {
      if (this.eventInfo.fraction_a
        && parseInt(this.eventInfo.fraction_a, 10) > 0) {
        return true;
      }
      return false;
    },
    hasFractionB() {
      if (this.eventInfo.fraction_b
        && parseInt(this.eventInfo.fraction_b, 10) > 0) {
        return true;
      }
      return false;
    },
    eventHeaderItems() {
      return {
        name: this.eventInfo.name,
        eventNo: this.eventInfo.no_number,
        fraction_a: this.eventInfo.fraction_a,
        fraction_b: this.eventInfo.fraction_b,
      };
    },
  },
  methods: {
    /**
     * 表單送出
     */
    onSubmit() {
      const errorList = [];
      this.$children.forEach((item) => {
        if (item.$options.name && item.$options.name.match(/^Form/i)) {
          item.test();
          errorList.push(item.error);
        }
      });
      if (errorList.indexOf(true) === -1) {
        const annualApplyInfo = JSON.parse(this.$cookies.get('annualApplyInfo'));
        annualApplyInfo.pay_id = this.payId;
        this.$cookies.set('annualApplyInfo', JSON.stringify(annualApplyInfo));
        // this.$router.push({ path: `/register/event/payment?event_id=${this.eventID}` });
      } else {
        // console.log('必填欄位尚未完成！');
      }
    },
  },
  components: {
    AnnualHeader,
    PageSubHeader,
  },
};
</script>
